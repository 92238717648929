import React, { FC, useEffect, useState } from 'react';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/common/DangerouslySetInnerHtml';
import UmbracoImage from 'gatsby-theme-husky/src/common/Image/UmbracoImage';
import SocialMediaShares from 'gatsby-theme-husky/src/components/SocialMediaShares';
import { toBoolean } from 'gatsby-theme-husky/src/utils/stringUtils/stringUtils';
import { getEmbedScripts } from 'gatsby-theme-husky/src/utils/functions';
import { Helmet } from 'react-helmet';
import ArticleLayout from 'gatsby-theme-husky/src/components/Article/ArticleLayout';
import { ArticleProps, STRUCTURE } from './models.d';

import 'gatsby-theme-husky/src/components/Article/Article.scss';
import './ArticleOverride.scss';

const Article: FC<ArticleProps> = ({
  title,
  titleUnderline,
  content,
  contentWithImages,
  articleBannerImage,
  articleImageAlt,
  articleBannerVideo,
  articleBackgroundColor,
  lang,
}) => {
  const [embeddedContent, setEmbeddedContent] = useState(content);
  const [embedScripts, setEmbedScripts] = useState<string[]>([]);

  useEffect(() => {
    if (typeof content !== 'undefined') {
      setEmbedScripts(getEmbedScripts(content)[0]);
      setEmbeddedContent(getEmbedScripts(content)[1]);
    }
  }, [content]);

  return (
    <ArticleLayout
      {...{ articleBannerImage, articleImageAlt, articleBannerVideo, articleBackgroundColor, lang }}
    >
      <Helmet>
        {embedScripts?.map((item) => (
          <script async src={item} type="text/javascript" />
        ))}
      </Helmet>
      <div className="article container" data-testid="article">
        <div className="article__title">
          <h1
            style={{
              textDecorationLine:
                titleUnderline && toBoolean(titleUnderline) ? 'underline' : 'none',
            }}
          >
            {title}
          </h1>
          <SocialMediaShares {...{ socialMediaSharesLang: lang }} />
        </div>
        <div className="article__body" data-testid="article-body">
          {embeddedContent ? (
            <DangerouslySetInnerHtml html={embeddedContent} />
          ) : (
            contentWithImages?.map(
              (
                { structure, rte, imageAlt, image }: PageContent.ContentWithImagesType,
                index: number
              ) =>
                ({
                  [STRUCTURE.RTE]: <DangerouslySetInnerHtml html={rte} key={index} />,
                  [STRUCTURE.IMAGE]: (
                    <UmbracoImage
                      image={image}
                      alt={imageAlt!}
                      className="article__body-image"
                      key={index}
                    />
                  ),
                }[structure])
            )
          )}
        </div>
      </div>
    </ArticleLayout>
  );
};

export default Article;
