import React, { FC } from 'react';

import Banner from 'common/Banner';
import SignUpBanner from 'components/SignUpBanner';
import { getHexColor } from 'utils/umbracoControlsUtils';
import { ArticleLayoutProps } from './models';

const ArticleLayout: FC<ArticleLayoutProps> = ({
  children,
  articleBannerImage,
  articleImageAlt,
  articleBannerVideo,
  articleBackgroundColor,
  lang,
}) => {
  return (
    <div className="article-layout" data-testid="article-layout">
      <Banner
        imageAsBackground={articleBannerImage}
        imageAsBackgroundAlt={articleImageAlt}
        withVideo={articleBannerVideo?.length ? articleBannerVideo : null}
        bannerVariant={articleBannerVideo ? ['video'] : ['main']}
        lazyload={false}
      />
      <div
        style={{ backgroundColor: articleBackgroundColor && getHexColor(articleBackgroundColor) }}
        data-testid="article-child-wrapper"
      >
        {children}
      </div>
      <SignUpBanner {...{ lang }} />
    </div>
  );
};

export default ArticleLayout;
