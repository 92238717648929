import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'gatsby-theme-husky/src/layout/Layout/Layout';
import Seo from 'gatsby-theme-husky/src/common/Seo';
import PageSchema from 'gatsby-theme-husky/src/common/PageSchema';
import Article from 'gatsby-theme-husky/src/components/Article';
import RelatedProducts from 'gatsby-theme-husky/src/components/RelatedProducts';
import RelatedArticles from 'gatsby-theme-husky/src/components/RelatedArticles';
import createBreadcrumbs from 'gatsby-theme-husky/src/utils/createBreadcrumbs';
import { ArticlePageProps } from 'gatsby-theme-husky/src/templates/ArticlePage/models';

import 'gatsby-theme-husky/src/templates/ArticlePage/articlePage.scss';

const ArticlePage: FC<ArticlePageProps> = ({
  data: {
    articlePage: {
      title,
      titleUnderline,
      content,
      contentWithImages,
      relatedProductsForArticles,
      articleBannerImage,
      articleImageAlt,
      articleBannerVideo,
      articleBackgroundColor,
      relatedArticlesTitle,
      langProps: { urls, lang },
      seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs },
    },
    allSitePage: { nodes },
    relatedProductsList,
    tipsAndTricks,
    relatedArticlesList: { relatedArticlesNodes },
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const breadcrumbs = {
    breadCrumb: createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname),
  };
  
  return (
    <Layout className="article-page" breadcrumbs={breadcrumbs} langProps={{ urls, lang }}>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <Article
        {...{
          articleBannerImage,
          articleImageAlt,
          articleBannerVideo,
          articleBackgroundColor,
          title,
          titleUnderline,
          content,
          contentWithImages,
          lang,
        }}
      />
      {relatedProductsList?.relatedProductsNodes.length && relatedProductsForArticles ? (
        <RelatedProducts
          {...{
            relatedProductsNodes: relatedProductsList.relatedProductsNodes,
            relatedProductsTitle: relatedProductsForArticles.relatedProductsTitle,
          }}
        />
      ) : null}
      {relatedArticlesNodes ? (
        <RelatedArticles
          {...{
            relatedArticles: relatedArticlesNodes,
            tipsAndTricks,
            relatedArticlesTitle,
          }}
        />
      ) : null}
    </Layout>
  );
};

export const query = graphql`
  query(
    $url: String!
    $lang: String
    $relatedProductsValues: [String]
    $relatedUrlArticles: [String]
  ) {
    relatedProductsList: allSalsifyProducts(
      filter: { sku: { in: $relatedProductsValues }, langProps: { lang: { eq: $lang } } }
    ) {
      relatedProductsNodes: nodes {
        localProductImage {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        relatedProducts {
          relatedProductsLearnMore
          buyNow {
            link {
              url
              target
              name
            }
            ariaLabel
            text
            withReverse
          }
        }
        productTitle
        productDescriptionLong
        productTitleWithSize
        url
      }
    }
    articlePage: umbracoArticles(url: { eq: $url }) {
      url
      title
      titleUnderline
      content
      contentWithImages {
        ...contentWithImages
      }
      relatedProductsForArticles {
        relatedProductsTitle
      }
      articleBannerImage {
        ...umbracoImage
      }
      relatedArticlesTitle
      articleImageAlt
      articleBannerVideo {
        link
        title
        autoplay
        image {
          fallbackUrl
          fluid {
            base64
            originalImg
            srcSet
          }
          variants {
            alias
            fallbackQuery
            type
            url
          }
        }
      }
      articleBackgroundColor {
        label
        value
      }
      langProps {
        urls {
          ...languageUrls
        }
        lang
      }
      seoProps {
        seoMetaTitle
        seoMetaKeywords
        seoMetaDescription
        seoExternalHreflangs {
          key
          value
        }
      }
    }
    tipsAndTricks: allUmbracoTipsAndTricks(filter: { langProps: { lang: { eq: $lang } } }) {
      relatedArticlesButtonNodes: nodes {
        relatedArticlesButton {
          ariaLabel
          text
        }
      }
    }
    relatedArticlesList: allUmbracoArticles(
      filter: { url: { in: $relatedUrlArticles }, langProps: { lang: { eq: $lang } } }
    ) {
      relatedArticlesNodes: nodes {
        title
        teaser
        url
        articleBannerImageAltText
        articleBannerImage {
          ...umbracoImage
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
  }
`;

export default ArticlePage;
